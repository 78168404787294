

const dataGames = {
    'spooky-spins': {
        name: 'Spooky Spins',
        desc: 'Get ready for a frightful adventure in Spooky Spins! This Halloween slot is packed with haunted reels, wickedly fun features and a chance to win over x20000+ your bet! Spin if you dare - spooky rewards are just waiting to be claimed!',
        features: [
            '10 Lines',
            'Expanding Wild',
            'Bonus',
            'Bonus Feature',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/spooky-spins/0.png'), 
            require('../images/games/spooky-spins/1.png'),
            require('../images/games/spooky-spins/2.png'),
            require('../images/games/spooky-spins/3.png'),
            require('../images/games/spooky-spins/4.png'),
        ],
        url: 'https://static.redstone.rs/games/spooky-spins/',
        bg: require('../images/games/spooky-spins/bg.png'),
    },
    '5-wild-fire': {
        name: '5 Wild Fire',
        desc: 'Experience the classic fun of 5 Wild Fire! Set all five reels on fire for a chance to multiply your stake by an incredible x2000! Ready to play?',
        features: [
            '5 Lines',
            'Expanding Wild',
            '5x3',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/5-wild-fire/0.png'), 
            require('../images/games/5-wild-fire/1.png'),
            require('../images/games/5-wild-fire/2.png'),
            require('../images/games/5-wild-fire/3.png'),
        ],
        url: 'https://static.redstone.rs/games/5-wild-fire/',
    },
    'volcano-hot': {
        name: 'Volcano Hot',
        desc: `Get ready for a lava-hot adventure with Volcano Hot! Stacked wilds and scatter symbols add excitement to every spin, offering a chance to win big!`,
        features: [
            'Wild Symbol',
            'Classic Mechanics',
            '20 lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/volcano-hot/0.png'), 
            require('../images/games/volcano-hot/1.png'),
            require('../images/games/volcano-hot/2.png'),
            require('../images/games/volcano-hot/3.png'),
        ],
        url: 'https://static.redstone.rs/games/volcano-hot/',
    },
    'volcano-crown': {
        name: 'Volcano Crown',
        desc: `Feel the lava flow as you spin for regal rewards. Erupt with excitement and claim your Volcano Crown today!`,
        features: [
            'Expanding wild',
            '2 Scatter Symbols',
            '10 lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/volcano-crown/0.png'), 
            require('../images/games/volcano-crown/1.png'),
            require('../images/games/volcano-crown/2.png'),
            require('../images/games/volcano-crown/3.png'),
        ],
        url: 'https://static.redstone.rs/games/volcano-crown/',
    },
    '100-clover-fire': {
        name: '100 Clover Fire',
        desc: `100 Clover Fire means 100 ways to win! Feel the thrill, spin the reels, and blaze your path to massive wins!`,
        features: [
            'Expanding wild',
            '100 Lines',
            'Scatter Symbol',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/100-clover-fire/0.png'), 
            require('../images/games/100-clover-fire/1.png'),
            require('../images/games/100-clover-fire/2.png'),
            require('../images/games/100-clover-fire/3.png'),
        ],
        url: 'https://static.redstone.rs/games/100-clover-fire/',
    },
    'dice-double': {
        name: 'Dice Double',
        desc: `Dive into Dice Double, the new slot game that doubles your excitement! Land on the middle reel with a double expanding symbol and watch your wins double, making every spin a thrilling experience.`,
        features: [
            'Two expand symbols',
            '5 Lines',
            'Scatter Symbol',
            'Multiplier',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/dice-double/0.png'), 
            require('../images/games/dice-double/1.png'),
            require('../images/games/dice-double/2.png'),
            require('../images/games/dice-double/3.png'),
        ],
        url: 'https://static.redstone.rs/games/dice-double/',
    },
    'double-clover-fire': {
        name: 'Double Clover Fire',
        desc: `Discover the blazing excitement of Double Clover Fire and spin your way to
        doubled wins today!`,
        features: [
            'Two expand symbols',
            '5 Lines',
            'Scatter Symbol',
            'Multiplier',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/double-clover-fire/0.png'), 
            require('../images/games/double-clover-fire/1.png'),
            require('../images/games/double-clover-fire/2.png'),
            require('../images/games/double-clover-fire/3.png'),
        ],
        url: 'https://static.redstone.rs/games/double-clover-fire/',
    },
    'spin-up': {
        name: 'Spin Up',
        desc: `Get ready to Spin Up and experience nostalgic vibes! Let the five ladies spark up your reels and bring big wins up to x2000! It's time to spin, win big and relive those classic vibes!`,
        features: [
            'Expanding wild',
            '5x3',
            '5 Lines',
            'Two Scatters',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/spin-up/0.png'), 
            require('../images/games/spin-up/1.png'),
            require('../images/games/spin-up/2.png'),
            require('../images/games/spin-up/3.png'),
        ],
        url: 'https://static.redstone.rs/games/spin-up/',
    },
    '81-vegas-crown': {
        name: '81 Vegas Crown',
        desc: 'Feel the Vegas atmosphere with 81 Vegas Crown, land crown symbols and get some juicy multipliers!',
        features: [
            '81 Ways',
            '4x3',
            'Win Multiplier',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/81-vegas-crown/0.png'), 
            require('../images/games/81-vegas-crown/1.png'),
            require('../images/games/81-vegas-crown/2.png'),
            require('../images/games/81-vegas-crown/3.png'),
        ],
        url: 'https://static.redstone.rs/games/81-vegas-crown/',
    },
    'apollo-27-classic': {
        name: 'Apollo 27 Classic',
        desc: 'Spin the fruity reels and chase double wins! With 27 ways for payout, this game is a juicy classic! Experience the fun and excitement as you spin your way to amazing rewards.',
        features: [
            '27 Ways',
            '3x3',
            'Win Multiplier',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/apollo-27-classic/0.png'), 
            require('../images/games/apollo-27-classic/1.png'),
            require('../images/games/apollo-27-classic/2.png'),
            require('../images/games/apollo-27-classic/3.png'),
        ],
        url: 'https://static.redstone.rs/games/apollo-27-classic/',
    },
    'hot-rush-fruit-lines': {
        name: 'HOT RUSH Fruit Lines',
        desc: 'Introducing Fruit Lines, your ticket to classic slot excitement! Spin the reels and line up your favorite fruits for a taste of sweet winnings.',
        features: [
            'Classic mechanics',
            '5x3',
            '5 Lines'
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/hot-rush-fruit-lines/0.png'), 
            require('../images/games/hot-rush-fruit-lines/1.png'),
            require('../images/games/hot-rush-fruit-lines/2.png'),
            require('../images/games/hot-rush-fruit-lines/3.png'),
        ],
        url: 'https://static.redstone.rs/games/hot-rush-fruit-lines/',
    },
    'book-of-scorpio': {
        name: 'Book Of Scorpio',
        desc: 'Discover ancient secrets in this thrilling slot! Trigger the bonus round with 3 books to reveal 2 expanding symbols for epic wins. Explore the secrets of Scorpio and claim your riches!',
        features: [
            'Bonus',
            'Two Expanding Symbols',
            'Book Mechanics'
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/book-of-scorpio/0.png'), 
            require('../images/games/book-of-scorpio/1.png'),
            require('../images/games/book-of-scorpio/2.png'),
            require('../images/games/book-of-scorpio/3.png'),
        ],
        url: 'https://static.redstone.rs/games/book-of-scorpio/',
    },
    'hot-rush-both-ways': {
        name: 'HOT RUSH Both Ways',
        desc: 'Get a taste of double delight in our HOT RUSH Both Ways! Win from left to right and right to left for double the chances to win big! Easy to play, impossible to resist. Start spinning now!',
        features: [
            'Bothways',
            '5 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/hot-rush-both-ways/0.png'), 
            require('../images/games/hot-rush-both-ways/1.png'),
            require('../images/games/hot-rush-both-ways/2.png'),
            require('../images/games/hot-rush-both-ways/3.png'),
        ],
        url: 'https://static.redstone.rs/games/hot-rush-both-ways/',
    },
    'caps-and-crowns': {
        name: 'Caps and Crowns',
        desc: 'Cross the royal threshold with Caps and Crowns, and hit it big where caps and crowns boost your spins for wins up to 2600x!',
        features: [
            'Two expand symbols',
            '5 Lines',
            'Scatter Symbol',
            'Multiplier',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/caps-and-crowns/0.png'), 
            require('../images/games/caps-and-crowns/1.png'),
            require('../images/games/caps-and-crowns/2.png'),
            require('../images/games/caps-and-crowns/3.png'),
        ],
        url: 'https://static.redstone.rs/games/caps-and-crowns/',
    },
    '5-clover-fire-lnc': {
        name: '5 Clover Fire Lock & Cash',
        desc: 'Spin, lock, and watch your luck bloom with 5 Clover Fire - Lock & Cash: Unleash the power of the clover Wild symbol across 5 lines, turning each spin into a cash-filled delight.',
        features: [
            '5 Lines',
            'Lock & Cash',
            'Bonus',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/5-clover-fire-lnc/0.png'), 
            require('../images/games/5-clover-fire-lnc/1.png'),
            require('../images/games/5-clover-fire-lnc/2.png'),
            require('../images/games/5-clover-fire-lnc/3.png'),
        ],
        url: 'https://static.redstone.rs/games/5-clover-fire-lnc/',
    },
    'hot-rush-stars-deluxe': {
        name: 'HOT RUSH Stars Deluxe',
        desc: 'Step into the world of HOT RUSH: Stars Deluxe, where simplicity meets excitement! With just 5 lines, this classic game ensures a journey packed with wins, elevating the thrill with each spin.',
        features: [
            '5 Lines',
            'Scatter Symbol',
            'Classic Mechanics',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/hot-rush-stars-deluxe/0.png'), 
            require('../images/games/hot-rush-stars-deluxe/1.png'),
            require('../images/games/hot-rush-stars-deluxe/2.png'),
            require('../images/games/hot-rush-stars-deluxe/3.png'),
        ],
        url: 'https://static.redstone.rs/games/hot-rush-stars-deluxe/',
    },
    'slot-royale': {
        name: 'Slot Royale',
        desc: 'Experience the elegance of Slot Royale with every spin, revealing exciting wins up to x1500! Enjoy gleaming fruits and a majestic crown in this timeless slot game.',
        features: [
            '10 Lines',
            'Expanding Wild Symbol',
            'Scatter Symbols',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/slot-royale/0.png'), 
            require('../images/games/slot-royale/1.png'),
            require('../images/games/slot-royale/2.png'),
            require('../images/games/slot-royale/3.png'),
        ],
        url: 'https://static.redstone.rs/games/slot-royale/',
    },
    'lady-triple-fortune': {
        name: 'Lady Triple Fortune',
        desc: 'Unlock the fortunes in Lady Triple Fortune, where every expanding wild boosts your chances at an incredible 1200x win!',
        features: [
            '5 Lines',
            'Expanding Wild Symbol',
            'Scatter Symbols',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/lady-triple-fortune/0.png'), 
            require('../images/games/lady-triple-fortune/1.png'),
            require('../images/games/lady-triple-fortune/2.png'),
            require('../images/games/lady-triple-fortune/3.png'),
        ],
        url: 'https://static.redstone.rs/games/lady-triple-fortune/',
    },
    'hot-rush-crown-burst': {
        name: 'HOT RUSH Crown Burst',
        desc: 'In HOT RUSH: Crown Burst, every expanding wild brings a rush of adrenaline and the chance for a 1200x cosmic payout!',
        features: [
            '5 Lines',
            'Expanding Wild Symbol',
            'Scatter Symbols',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/hot-rush-crown-burst/0.png'), 
            require('../images/games/hot-rush-crown-burst/1.png'),
            require('../images/games/hot-rush-crown-burst/2.png'),
            require('../images/games/hot-rush-crown-burst/3.png'),
        ],
        url: 'https://static.redstone.rs/games/hot-rush-crown-burst/',
    },
    'chilli-double': {
        name: 'Chilli Double',
        desc: 'Feel the heat, embrace the thrill, and let the Chilli Double bring the flavor of big wins with expanding wild 2x multiplier!',
        features: [
            '5 Lines',
            'Expanding Wild Symbol',
            'Multiplier',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/chilli-double/0.png'), 
            require('../images/games/chilli-double/1.png'),
            require('../images/games/chilli-double/2.png'),
            require('../images/games/chilli-double/3.png'),
        ],
        url: 'https://static.redstone.rs/games/chilli-double/',
        },
    'jolly-presents': {
        name: 'Jolly Presents',
        desc: 'Get wrapped up in the fun of Jolly Presents, where expanding wilds mean big wins up to x1200! Holiday joy is just a spin away!',
        features: [
            '5 Lines',
            'Expanding Wild Symbol',
            'Scatter Symbols',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/jolly-presents/0.png'), 
            require('../images/games/jolly-presents/1.png'),
            require('../images/games/jolly-presents/2.png'),
            require('../images/games/jolly-presents/3.png'),
        ],
        url: 'https://play.redstone.rs/games/jolly-presents/index.html',
        bg: require('../images/games/10-wild-santa/bg.png'),
    },
    '20-wild-crown': {
        name: '20 Wild Crown',
        desc: `Experience the thrill of expanding wilds. It's all in the spin!`,
        features: [
            '20 Lines',
            'Expanding Wild Symbol',
            '2 Scatter Symbols',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/20-wild-crown/0.png'), 
            require('../images/games/20-wild-crown/1.png'),
            require('../images/games/20-wild-crown/2.png'),
            require('../images/games/20-wild-crown/3.png'),
        ],
        url: 'https://static.redstone.rs/games/20-wild-crown/',
    },
    '40-jokers-free-games': {
        name: '40 Jokers Free Games',
        desc: 'Stack wild Jokers and chase free games in a game of chance and luck!',
        features: [
            '40 Lines',
            'Stacked Wild Symbol',
            'Free Games',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-jokers-free-games/0.png'), 
            require('../images/games/40-jokers-free-games/1.png'),
            require('../images/games/40-jokers-free-games/2.png'),
            require('../images/games/40-jokers-free-games/3.png'),
        ],
        url: 'https://static.redstone.rs/games/40-jokers-free-games/',
    },
    'moon-dog': {
        name: 'Moon Dog',
        desc: 'Explore the Moon Dog universe, the ultimate playground!',
        features: [
            '40 Lines',
            'Stacked Wild Symbol',
            'Free Games',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/moon-dog/0.png'), 
            require('../images/games/moon-dog/1.png'),
            require('../images/games/moon-dog/2.png'),
            require('../images/games/moon-dog/3.png'),
        ],
        url: 'https://static.redstone.rs/games/moon-dog/',
    },
    'wild-trail': {
        name: 'Wild Trail',
        desc: 'Set off on a Wild Trail adventure across 40 lines with thrilling bonus games, and get a chance to win up to 2000x your stake!',
        features: [
            '40 Lines',
            'Stacked Wild Symbol',
            'Free Games',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/wild-trail/0.png'), 
            require('../images/games/wild-trail/1.png'),
            require('../images/games/wild-trail/2.png'),
            require('../images/games/wild-trail/3.png'),
        ],
        url: 'https://play.redstone.rs/games/wild-trail/index.html',
    },
    'wild-heart-beat': {
        name: 'Wild HEART Beat',
        desc: 'Wild HEART Beat sets the rhythm for big wins, with expanding wilds that could lead to a heart-stopping 2000x payout!',
        features: [
            '5 Lines',
            'Expanding Wild Symbol',
            'Scatter Symbols',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/wild-heart-beat/0.png'), 
            require('../images/games/wild-heart-beat/1.png'),
            require('../images/games/wild-heart-beat/2.png'),
            require('../images/games/wild-heart-beat/3.png'),
        ],
        url: 'https://play.redstone.rs/games/wild-heart-beat/index.html',
    },
    '40-fruit-frenzy': {
        name: '40 Fruit Frenzy',
        desc: 'New adventure awaits! Fruit Frenzy is stacked with 40 lines and offers frenzied wins that can rack up huge rewards.',
        features: [
            '40 Lines',
            'Wild Symbol',
            'Scatter Symbol',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-fruit-frenzy/0.png'), 
            require('../images/games/40-fruit-frenzy/1.png'),
            require('../images/games/40-fruit-frenzy/2.png'),
            require('../images/games/40-fruit-frenzy/3.png'),
        ],
        url: 'https://play.redstone.rs/games/40-fruit-frenzy/index.html',
    },
    '20-fruit-frenzy': {
        name: '20 Fruit Frenzy',
        desc: 'Get ready for some juicy fruits. Fruit Frenzy, with its 20 lines, offers high rewards, with some wins that can go up to 1,000x.',
        features: [
            '20 Lines',
            'Wild Symbol',
            'Scatter Symbol',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/20-fruit-frenzy/0.png'), 
            require('../images/games/20-fruit-frenzy/1.png'),
            require('../images/games/20-fruit-frenzy/2.png'),
            require('../images/games/20-fruit-frenzy/3.png'),
        ],
        url: 'https://play.redstone.rs/games/20-fruit-frenzy/index.html',
    },
    'chilli-hot-5': {
        name: 'Chilli Hot 5',
        desc: 'Spice it up with Chilli Hot 5 for fiery wins up to 1200x. Chilli Wilds expand across the middle three reels for maximum hotness!',
        features: [
            '5 Lines',
            'Expanding Wild',
            'Scatters',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/chilli-hot-5/0.png'), 
            require('../images/games/chilli-hot-5/1.png'),
            require('../images/games/chilli-hot-5/2.png'),
            require('../images/games/chilli-hot-5/3.png'),
        ],
        url: 'https://play.redstone.rs/games/chilli-hot-5/index.html',
    },
    '40-hot-joker': {
        name: '40 Hot Joker',
        desc: 'It’s getting hot in here! Get lucky with Hot Joker wilds, expanding across the middle 3 reels for massive wins on 40 lines and up to 3000x!',
        features: [
            '40 Lines',
            'Expanding Wild',
            'Scatters',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-hot-joker/0.png'), 
            require('../images/games/40-hot-joker/1.png'),
            require('../images/games/40-hot-joker/2.png'),
            require('../images/games/40-hot-joker/3.png'),
        ],
        url: 'https://play.redstone.rs/games/40-hot-joker/index.html',
    },
    'fear-of-dark': {
        name: 'Fear of Dark',
        desc: 'Face Fear of Dark and win up to skin crawling 3000x!',
        features: [
            '40 Lines',
            'Expanding Wild',
            'Scatters',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/fear-of-dark/0.png'), 
            require('../images/games/fear-of-dark/1.png'),
            require('../images/games/fear-of-dark/2.png'),
            require('../images/games/fear-of-dark/3.png'),
        ],
        url: 'https://play.redstone.rs/games/fear-of-dark/index.html',
    },
    'clover-blast-5': {
        name: 'Clover Blast 5',
        desc: 'Give Clover Blast 5 a spin. Hit 5 clovers and you could win up to 2000x your bet.',
        features: [
            '5 Lines',
            'Expanding Wild',
            '5x3',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/clover-blast-5/0.png'), 
            require('../images/games/clover-blast-5/1.png'),
            require('../images/games/clover-blast-5/2.png'),
            require('../images/games/clover-blast-5/3.png'),
            require('../images/games/clover-blast-5/4.png'), 
        ],
        url: 'https://play.redstone.rs/games/clover-blast-5/index.html',
    },
    'hot-hot': {
        name: 'Hot Hot Stereo Win',
        desc: 'Hot Hot, where fiery lava rivers meet blazing opportunities, promises a gaming experience that sparks winnings in both directions.',
        features: [
            '5 Lines',
            'Bothways',
            '5x3',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/hot-hot/0.png'),
            require('../images/games/hot-hot/1.png'),
            require('../images/games/hot-hot/2.png'),
            require('../images/games/hot-hot/3.png'), 
        ],
        url: 'https://play.redstone.rs/games/hot-hot/index.html',
    },
    'blazing-heat': {
        name: 'Blazing HEAT',
        desc: 'Step into the world of Blazing HEAT. Every win boosts your gameplay, adding a layer of excitement to your journey for rewards.',
        features: [
            '5 Lines',
            'Classic Mechanics',
            'Scatter',
            '5x3',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/blazing-heat/0.png'),
            require('../images/games/blazing-heat/1.png'),
            require('../images/games/blazing-heat/2.png'),
            require('../images/games/blazing-heat/3.png'), 
        ],
        url: 'https://play.redstone.rs/games/blazing-heat/index.html',
    },
    'heat-double': {
        name: 'HEAT Double',
        desc: 'Experience the fiery fun of HEAT Double, where filling all reels with the same fruit symbol rewards you with a 2x multiplier! Dive into this fruit-themed slot and enjoy a delicious gaming experience.',
        features: [
            '5 Lines',
            'Win Multiplier',
            '3x3',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/heat-double/0.png'),
            require('../images/games/heat-double/1.png'),
            require('../images/games/heat-double/2.png'),
            require('../images/games/heat-double/3.png'), 
        ],
        url: 'https://play.redstone.rs/games/heat-double/index.html',
    },
    'super-lucky': {
        name: 'Super Lucky',
        desc: 'Looking for some excitement? Super Lucky has got you covered! With its thrilling Lock and Cash features, every spin is a chance to strike it big.',
        features: [
            '5 Lines',
            'Lock&Cash Bonus',
            'GRAND Prize',
            'Wild Symbol',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/super-lucky/0.png'),
            require('../images/games/super-lucky/1.png'),
            require('../images/games/super-lucky/2.png'),
            require('../images/games/super-lucky/3.png'), 
            require('../images/games/super-lucky/4.png'), 

        ],
        url: 'https://play.redstone.rs/games/super-lucky/index.html',
    },
    '40-fire-cash': {
        name: '40 Fire Cash',
        desc: 'Step back in time and enjoy retro design of this hot fruit slot game. Immerse yourself in the excitement and try your luck to win big today!',
        features: [
            'Scatter Symbol',
            'Wild Symbol',
            '40 Lines'
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-fire-cash/0.png'),
            require('../images/games/40-fire-cash/1.png'),
            require('../images/games/40-fire-cash/2.png'),
            require('../images/games/40-fire-cash/3.png'), 
        ],
        url: 'https://play.redstone.rs/games/40-fire-cash/index.html',
    },
    '20-fire-cash': {
        name: '20 Fire Cash',
        desc: 'With classic mechanics and retro design, this game will transport you back in time. Try your luck and spin to win today!',
        features: [
            'Scatter Symbol',
            'Wild Symbol',
            '20 Lines'
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/20-fire-cash/0.png'),
            require('../images/games/20-fire-cash/1.png'),
            require('../images/games/20-fire-cash/2.png'),
            require('../images/games/20-fire-cash/3.png'), 
        ],
        url: 'https://play.redstone.rs/games/20-fire-cash/index.html',
    },
    '10-wild-santa': {
        name: '10 Wild Santa',
        desc: 'Santa came early this year. Enjoy the generous wins and exciting features of expanding Santa in 5x3 reel slot game and grab your Christmas gifts. You deserve it!',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '10 Lines'
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/10-wild-santa/0.png'),
            require('../images/games/10-wild-santa/1.png'),
            require('../images/games/10-wild-santa/2.png'),
            require('../images/games/10-wild-santa/3.png'), 
            require('../images/games/10-wild-santa/4.png'), 
        ],
        url: 'https://play.redstone.rs/games/10-wild-santa/index.html',
        bg: require('../images/games/10-wild-santa/bg.png'),
    },
    '5-crown-fire': {
        name: '5 Crown Fire',
        desc: 'Royal games give royal wins! Find your lucky crowns and feel the power of fruits with new 5 lined game!',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '10 Lines'
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/5-crown-fire/0.png'),
            require('../images/games/5-crown-fire/1.png'),
            require('../images/games/5-crown-fire/2.png'),
            require('../images/games/5-crown-fire/3.png'), 
        ],
        url: 'https://play.redstone.rs/games/5-crown-fire/index.html',
    },
    'heat-classic-5': {
        name: 'HEAT Classic 5',
        desc: 'Fire up the reels and feel the heat of winning big! HEAT Classic 5 is a future timeless classic with the perfect blend of retro mechanics and modern looks.',
        features: [
            'Classic Slot',
            '3x3',
            '5 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/heat-classic-5/0.png'),
            require('../images/games/heat-classic-5/1.png'),
            require('../images/games/heat-classic-5/2.png'),
            require('../images/games/heat-classic-5/3.png'), 
        ],
        url: 'https://play.redstone.rs/games/heat-classic-5/index.html',
    },
    'book-of-dread': {
        name: 'Book of Dread',
        desc: 'Leave your fears behind. Collect 3 or more books and step into the world of darkness. The amount you can win is TERRIFYING!',
        features: [
            'Book Mechanics',
            'Two Bonus Symbols',
            '10 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/book-of-dread/0.png'),
            require('../images/games/book-of-dread/1.png'),
            require('../images/games/book-of-dread/2.png'),
            require('../images/games/book-of-dread/3.png'), 
            require('../images/games/book-of-dread/4.png'),
        ],
        url: 'https://play.redstone.rs/games/book-of-dread/index.html',
        bg: require('../images/games/book-of-dread/bg.png'),
    },
    '10-wild-pumpkin': {
        name: '10 Wild Pumpkin',
        desc: '’Tis the spooky season! Land up to 3 expanding Wild Pumpkins for massive wins on up to 10 lines.',
        features: [
            'Expanding Wild',
            'Two Scatters',
            '10 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/10-wild-pumpkin/0.jpg'),
            require('../images/games/10-wild-pumpkin/1.jpg'),
            require('../images/games/10-wild-pumpkin/2.jpg'),
            require('../images/games/10-wild-pumpkin/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/10-wild-pumpkin/index.html',
        bg: require('../images/games/10-wild-pumpkin/bg.png'),
    },
    'clover-coin': {
        name: 'Clover Coin',
        desc: 'Classic feel, juicy fruits and lucky clovers! Collect 6 or more Clover Coins to trigger Lock&Cash Bonus feature where you can score GRAND Bonus prize by collecting all 15 coins.',
        features: [
            'Lock&Cash Bonus',
            'GRAND Prize',
            'Wild Symbol',
            '10 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/clover-coin/0.jpg'),
            require('../images/games/clover-coin/1.jpg'),
            require('../images/games/clover-coin/2.jpg'),
            require('../images/games/clover-coin/3.jpg'),
            require('../images/games/clover-coin/4.jpg'),
        ],
        url: 'https://play.redstone.rs/games/clover-coin/index.html'
    },
    '10-wild-dice': {
        name: '10 Wild Dice',
        desc: 'Roll the dice and feel the heat of victory, get expanding WILD symbol and increase your chance to win huge!',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '10 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/10-wild-dice/0.jpg'),
            require('../images/games/10-wild-dice/1.jpg'),
            require('../images/games/10-wild-dice/2.jpg'),
            require('../images/games/10-wild-dice/3.jpg'),
            require('../images/games/10-wild-dice/4.jpg'),
        ],
        url: 'https://play.redstone.rs/games/10-wild-dice/index.html'
    },
    'mayan-coins': {
        name: 'Mayan Coins',
        desc: 'Discover Ancient world of Maya. Seek the thrill and win exotic riches by collecting Mayan Coins. 6 coins trigger Lock&Cash bonus in which you can score GRAND prize by collecting all 15 coins.',
        features: [
            'Lock&Cash Bonus',
            'GRAND Prize',
            'Wild Symbol',
            '10 Lines'
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/mayan-coins/0.jpg'),
            require('../images/games/mayan-coins/1.jpg'),
            require('../images/games/mayan-coins/2.jpg'),
            require('../images/games/mayan-coins/3.jpg'),
            require('../images/games/mayan-coins/4.jpg'),
        ],
        url: 'https://play.redstone.rs/games/mayan-coins/index.html'
    },
    '10-wild-crown': {
        name: '10 Wild Crown',
        desc: 'Hot and explosive experience. Conquer luck and land up to 3 Expanding Wilds for massive wins. The Crown is yours!',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '10 Lines'
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/10-wild-crown/0.jpg'),
            require('../images/games/10-wild-crown/1.jpg'),
            require('../images/games/10-wild-crown/2.jpg'),
            require('../images/games/10-wild-crown/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/10-wild-crown/index.html'
    },
    '40-cash-bells': {
        name: '40 Cash Bells',
        desc: 'The nostalgic adventure to shoot you back in time. 40 Cash Bells is a retro classic that features stacked fruits and free spins. Ring the bells of victory!',
        features: [
            'Free Spins',
            'Stacked Wilds',
            '40 Lines',
            '6 Reels',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-cash-bells/0.jpg'),
            require('../images/games/40-cash-bells/1.jpg'),
            require('../images/games/40-cash-bells/2.jpg'),
            require('../images/games/40-cash-bells/3.jpg'),
            require('../images/games/40-cash-bells/4.jpg'),
        ],
        url: 'https://play.redstone.rs/games/40-cash-bells/index.html',
    },
    '40-clover-fire': {
        name: '40 Clover Fire',
        desc: 'Expanding Wilds and 40 lines for maximum chance to win. 40 Clover Fire is fast and explosive, and landing Clovers in middle 3 reels will light up big wins.',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '40 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-clover-fire/0.jpg'),
            require('../images/games/40-clover-fire/1.jpg'),
            require('../images/games/40-clover-fire/2.jpg'),
            require('../images/games/40-clover-fire/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/40-clover-fire/index.html'
    },
    'action-hot-20': {
        name: 'Action Hot 20',
        desc: 'Get ready for some hot action! Action Hot 20 is simple and fast but highly rewarding with fiery combos of stacked symbols that can rake up huge super wins.',
        features: [
            'Stacked Symbols',
            'Scatter',
            '20 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/action-hot-20/0.jpg'),
            require('../images/games/action-hot-20/1.jpg'),
            require('../images/games/action-hot-20/2.jpg'),
            require('../images/games/action-hot-20/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/action-hot-20/index.html'
    },
    'lost-book': {
        name: 'Lost Book',
        desc: 'The Ancient Egyptian riches await the brave. Journey to the mysterious Queen\'s chambers and if you\'re lucky enough she will reward mightily.',
        features: [
            'Free Spins',
            'Expanding Special Symbol',
            'Book Mechanics',
            '10 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/lost-book/0.jpg'),
            require('../images/games/lost-book/1.jpg'),
            require('../images/games/lost-book/2.jpg'),
            require('../images/games/lost-book/3.jpg'),
            require('../images/games/lost-book/4.jpg'),
        ],
        url: 'https://play.redstone.rs/games/lost-book/index.html'
    },
    '5-wild-heart': {
        name: '5 Wild Heart',
        desc: 'Explosive Wild wins that get your heart pumping! Whether lucky 7\'s, bells or juicy fruits, stacking them up next to Expanding Wilds will get you massive pay.',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '5 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/5-wild-heart/0.jpg'),
            require('../images/games/5-wild-heart/1.jpg'),
            require('../images/games/5-wild-heart/2.jpg'),
            require('../images/games/5-wild-heart/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/5-wild-heart/index.html'
    },
    '40-dice-fire': {
        name: '40 Dice Fire',
        desc: 'Roll the Dice and be an instant winner! Dice variant slot with 40 lines brings colorful wins with Expanding Wilds on reels 2, 3 and 4.',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '40 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-dice-fire/0.jpg'),
            require('../images/games/40-dice-fire/1.jpg'),
            require('../images/games/40-dice-fire/2.jpg'),
            require('../images/games/40-dice-fire/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/40-dice-fire/index.html'
    },
    '10-wild-diamond': {
        name: '10 Wild Diamond',
        desc: 'REDSTONE Diamonds are forever! Fire up all 9 expanded Diamonds and get blazing super win going. A classic in the making, 10 Wild Diamond is thrilling, fast and unforgettable.',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '10 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/10-wild-diamond/0.jpg'),
            require('../images/games/10-wild-diamond/1.jpg'),
            require('../images/games/10-wild-diamond/2.jpg'),
            require('../images/games/10-wild-diamond/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/10-wild-diamond/index.html'
    },
    'action-hot-40': {
        name: 'Action Hot 40',
        desc: 'Get ready for some hot action! Action Hot 40 is simple and fast but highly rewarding with fiery combos of stacked symbols that can rake up huge super wins.',
        features: [
            'Stacked Symbols',
            'Scatter',
            '40 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/action-hot-40/0.jpg'),
            require('../images/games/action-hot-40/1.jpg'),
            require('../images/games/action-hot-40/2.jpg'),
            require('../images/games/action-hot-40/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/action-hot-40/index.html'
    },
    '5-clover-fire': {
        name: '5 Clover Fire',
        desc: 'Expanding Wilds for maximum chance to win. 5 Clover Fire is fast and explosive, and landing Clovers in middle 3 reels will light up big wins.',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '5 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/5-clover-fire/0.jpg'),
            require('../images/games/5-clover-fire/1.jpg'),
            require('../images/games/5-clover-fire/2.jpg'),
            require('../images/games/5-clover-fire/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/5-clover-fire/index.html'
    },
    '5-dice-fire': {
        name: '5 Dice Fire',
        desc: 'Roll the Dice and be an instant winner! Dice variant slot with 5 lines brings colorful wins with Expanding Wilds on reels 2, 3 and 4.',
        features: [
            'Expanding Wild',
            '2 Scatters',
            '5 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/5-dice-fire/0.jpg'),
            require('../images/games/5-dice-fire/1.jpg'),
            require('../images/games/5-dice-fire/2.jpg'),
            require('../images/games/5-dice-fire/3.jpg'),
        ],
        url: 'https://play.redstone.rs/games/5-dice-fire/index.html'
    },
    '50-wild-cash': {
        name: '50 Wild Cash',
        desc: 'Wild Pots full of gold! With a little bit of luck you can land full reels of stacked Wild Pots for massive wins. Free Games symbols trigger Free Spins feature.',
        features: [
            'Stacked Wilds',
            'Free Spins',
            '50 Lines',
            '6 Reels',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/50-wild-cash/0.jpg'),
            require('../images/games/50-wild-cash/1.jpg'),
            require('../images/games/50-wild-cash/2.jpg'),
            require('../images/games/50-wild-cash/3.jpg'),
            require('../images/games/50-wild-cash/4.jpg'),
        ],
        url: 'https://play.redstone.rs/games/50-wild-cash/index.html'
    },
    '40-wild-dice': {
        name: '40 Wild Dice',
        desc: 'Roll the Wild Dice! Score massive wins with stacked dice symbols, and if that is not enough get 3 or more Free Games symbols to score Free Spins feature.',
        features: [
            'Stacked Wilds',
            'Free Spins',
            '40 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-wild-dice/0.jpg'),
            require('../images/games/40-wild-dice/1.jpg'),
            require('../images/games/40-wild-dice/2.jpg'),
            require('../images/games/40-wild-dice/3.jpg'),
            require('../images/games/40-wild-dice/4.jpg'),
        ],
        url: 'https://play.redstone.rs/games/40-wild-dice/index.html'
    },
    '40-wild-clover': {
        name: '40 Wild Clover',
        desc: 'This is your lucky Clover! With stacked symbols and juicy fruits, 40 Wild Clover is highly dynamic. To score even bigger wins, get 3 or more Free Games symbols and enter Free Spins feature.',
        features: [
            'Stacked Wilds',
            'Free Spins',
            '40 Lines',
        ],
        links: [
            'asd'
        ],
        imgs: [
            require('../images/games/40-wild-clover/0.jpg'),
            require('../images/games/40-wild-clover/1.jpg'),
            require('../images/games/40-wild-clover/2.jpg'),
            require('../images/games/40-wild-clover/3.jpg'),
            require('../images/games/40-wild-clover/4.jpg'),
        ],
        url: 'https://play.redstone.rs/games/40-wild-clover/index.html'
    },
};


export default dataGames